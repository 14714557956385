<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Failed certifications</h4>
                            </div>
                            <div class="col-md-9">
                            </div>
                            <div class="col-md-1">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line align-middle mr-2"></i> Export
                                </b-button>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="failedTab"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="row">
                                    <b-button class="actionBut" v-b-modal.modal-xl variant="info" v-b-tooltip.hover title="View" href="">
                                        <i class="ri-eye-line align-middle "></i>
                                        <b-modal
                                                id="modal-xl"
                                                size="xl"
                                                centered
                                                hide-footer
                                                title="Certification"
                                                title-class="font-18"
                                        >
                                            <div class="row form-group">
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Quality assurance : {{row.item.quality_assurance}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Overview of EID POCT : {{row.item.overview_of_eid_POCT}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Safety: {{row.item.safety}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Specimen collection : {{row.item.specimen_collection}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">EID POC testing algorithm : {{row.item.overview_of_eid_POCT}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Record keeping : {{row.item.record_keeping}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">EQA/PT : {{row.item.EQA_PT}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Professional ethics : {{row.item.professional_ethics}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Inventory : {{row.item.inventory}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Total score written : {{row.item.total_score_written}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Inventory : {{row.item.inventory}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Total practicals : {{row.item.total_practicals}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">% Performance written  : {{row.item.percentage_performance_written}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="d-inline-flex">
                                                        <p class="mr-3">Total % performance : {{row.item.total_percentage_performance}}</p>
                                                        <p class="mr-3 ">   </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-modal>
                                    </b-button>
                                    <b-button class="actionBut" v-b-modal.modal-xl1 variant="success" href="" v-b-tooltip.hover title="Edit">
                                        <i class="ri-edit-fill align-middle "></i>
                                        <b-modal
                                                id="modal-xl1"
                                                size="xl"
                                                ref="my-modal"
                                                centered
                                                hide-footer
                                                title="Edit Certification"
                                                title-class="font-18">
                                            <form ref="form" @submit.stop.prevent="editCertif(row.item)">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label >Registration code</label>
                                                            <input
                                                            v-model="row.item.registration_code"
                                                                    type="text"
                                                                    placeholder="Registration Code"
                                                                    class="form-control"
                                                                    readonly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label >Name and Surname</label>
                                                            <input v-model="row.item.name_and_surname"
                                                                    type="text"
                                                                    placeholder="Name and surname"
                                                                    class="form-control"
                                                                    readonly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4> Test scores</h4>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Quality assurance</label>
                                                            <input v-model="row.item.quality_assurance"
                                                                    type="number"
                                                                    placeholder="Quality assurance"
                                                                    class="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label> Overview of EID POCT</label>
                                                        <input v-model="row.item.overview_of_eid_POCT"
                                                                type="number"
                                                                placeholder="Overview of EID POCT"
                                                                class="form-control"
                                                        />

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label> Safety</label>
                                                        <input v-model="row.item.safety"
                                                                type="number"
                                                                placeholder="Safety"
                                                                class="form-control"
                                                        />

                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Specimen collection</label>
                                                            <input v-model="row.item.specimen_collection"
                                                                    type="number"
                                                                    placeholder="Specimen collection"
                                                                    class="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label> EID POC testing algorithm</label>
                                                        <input v-model="row.item.eid_poc_testing_algorithm"
                                                                type="number"
                                                                placeholder="EID POC testing algorithm"
                                                                class="form-control"
                                                        />

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label> Record keeping</label>
                                                        <input v-model="row.item.record_keeping"
                                                                type="number"
                                                                placeholder="Record keeping"
                                                                class="form-control"
                                                        />

                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>EQA/PT</label>
                                                            <input v-model="row.item.EQA_PT"
                                                                    type="number"
                                                                    placeholder="EQA/PT"
                                                                    class="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label> Professional ethics</label>
                                                        <input v-model="row.item.professional_ethics"
                                                                type="number"
                                                                placeholder="Professional ethics"
                                                                class="form-control"
                                                        />

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label> Inventory</label>
                                                        <input v-model="row.item.inventory"
                                                                type="number"
                                                                placeholder="Inventory"
                                                                class="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Total Score Written</label>
                                                                <input v-model="row.item.total_score_written"
                                                                    type="number"
                                                                    placeholder="Total Score Written"
                                                                    class="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Total Practicals</label>
                                                        <input v-model="row.item.total_practicals"
                                                            required
                                                                type="number"
                                                                placeholder="Total Practicals"
                                                                class="form-control"
                                                        />

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>% Performance written</label>
                                                        <input v-model="row.item.percentage_performance_written"
                                                            required
                                                                type="number"
                                                                placeholder="% Performance written"
                                                                class="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Total % Performance</label>
                                                            <input v-model="row.item.total_percentage_performance"
                                                            required
                                                                    type="number"
                                                                    placeholder="Total % Performance"
                                                                    class="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="display: flex; justify-content: flex-end;">
                                                    <b-button class="mr-2" variant="secondary" @click="hideModal">Close</b-button>
                                                    <b-button variant="primary" type="submit">Edit</b-button>
                                                </div>
                                            </form>
                                        </b-modal>
                                    </b-button>
                                    <!--<b-button class="actionBut" variant="dark"   v-b-modal.send-modal title="Print">
                                        <i class="ri-printer-line align-middle" ></i>
                                        <b-modal hide-footer id="send-modal" title="Send To Partner" title-class="font-18" ref="send-modal">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input v-model="email"
                                                           required
                                                           type="email"
                                                           placeholder="Email"
                                                           class="form-control"
                                                    />
                                                </div>
                                                <div>
                                                    <input type="file" accept="application/pdf" @change="chooseFile"/>
                                                </div>
                                                <div v-if="error">
                                                    <label>{{error}}</label>
                                                </div>
                                            </div>

                                            <div style="display: flex; justify-content: flex-end; margin-top: 10px">
                                                <b-button @click="send">Send</b-button>
                                            </div>
                                        </b-modal>
                                    </b-button>-->
                                    <!--                                    <span><b-btn @click="editItem(item)">Edit</b-btn></span>-->


                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import axios from 'axios'
    import {axios_get, axios_put, download_url, url_notification} from "../../../../helpers/helper";
    import { tableData, fields } from "./dataTrainers";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View Failed",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {

                failedTab: [],
                tableData: tableData,
                title: "View Failed certifications",
                items: [
                    {
                        text: "CQI",
                        href: "/"
                    },
                    {
                        text: "Failed certifications",
                        active: true
                    }
                ],
                totalRows: 1,
                user: null,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: fields,
                fichier: null,
                email: null,
                error: null
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            }
        },
        mounted() {
            this.user = JSON.parse(localStorage.getItem('user'))
            axios_get('/certif-failed', {}, (d)=>{
                this.failedTab = d.filter(e=>{
                    return e.region.country_id == this.user.country_id
                })
                this.totalRows = d.length;
                console.log(d)
            }, (err)=>{
                console.log(err)
            }, (f)=>{
                console.log(f)
            })
            this.totalRows = this.items.length;
        },
        methods: {
            chooseFile(e){
                this.fichier = e.target.files[0]
                console.log(this.fichier)
            },
            send(){
                if (this.fichier === null || this.email===null){
                    return;
                }
                this.error = null
                const formdata = new FormData();
                formdata.append('file', this.fichier)
                axios.post(download_url, formdata).then(res=>{
                    console.log(res)
                    const data = {
                        "to": this.email,
                        "subject": "GHSS Proficiency Testing Software",
                        "html":"<h1>Hi tamko</h1>" +
                        "<p>le lien pour télécharger le fichier: "+res.data.ressource+"</p>",
                    }
                    axios.get(url_notification+"/email", {params: data}).then(rep=>{
                        console.log(rep)
                        this.$refs['send-modal'].hide()
                    }).catch(err=>{
                        console.log(err)
                    })
                }).catch(err=>{
                    console.log(err)
                    this.error = "Veuillez réessayer"
                }).finally(()=>{
                    console.log("finally")
                })
            },
            editCertif(all){
                console.log(all)
                axios_put('/certif/'+all.id, all, (e)=>{
                    console.log('success', e)
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('final', f)
                }
                )
                this.hideModal()
            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left: 15px;
    }

</style>
